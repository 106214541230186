<template>
    <div class="err-message">
        <div>
            <h1>404</h1>
            <h4>Oops, Page Not found</h4>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NotFound',
        components: {
        },
        mounted() {
            this.$root.preloader = false;
        },
        data: function () {
            return {
            }
        }
    }
</script>

<style>
    .err-message {
        display: flex;
        justify-content: center;
        text-align: center;
        height: calc(100vh - 55.6px);
        align-items: center;
    }
    .err-message h1 {
        font-size: 3.5rem;
    }
</style>
